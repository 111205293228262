import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import './Card.css';
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as Delete } from "./delete.svg";


const Card = (props) => {
  
  const [images, setImages] = useState("")
  const [img, setImg] = useState("")
  const [page,setPage]=useState(props.page)

  const getPics=async() => {
    const request= await fetch('https://tvoi-semena.store/seedpictures', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        seedId: props.id,
      })
    })
    let content = await request.json();
    content=content.map(x=>x.picture)
    
    const res= await fetch('https://tvoi-semena.store/downloadpicture', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        file: content[0],
      })
    })
    .then(res => res.blob())
    .then(data=>{setImg(URL.createObjectURL(data))})
    .catch(error =>{
      console.log(error)
    })   
  } 
  let defaultVal = {
    name: "",
    desc: "",
    type: "",
    growType: "",
    provider: "",
    additionaiInfo: [{ [""]: "" }],
    categories: [""],
  };
  const [productInfo, setProduct] = useState(defaultVal);
  
  const fetchProduct = async () => {
    let content=defaultVal
    try{
    const request = await fetch("https://tvoi-semena.store/seedallInfo", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        seedId: props.id,
      }),
    });
    content = await request.json();
  }catch{}
    if (content.categories !== null) {
      let growType = content.categories
        .filter((x) => x.categoryType === "growType")
        .map((x) => x.name)
        .join(", ");
      let obj1 = {
        categoryType: "growType",
        name: growType,
      };
      let seedType = content.categories
        .filter((x) => x.categoryType === "seedType")
        .map((x) => x.name)
        .join(", ");
      let obj2 = {
        categoryType: "seedType",
        name: seedType,
      };
      let provider = content.categories
        .filter((x) => x.categoryType === "provider")
        .map((x) => x.name)
        .join(", ");
      let obj3 = {
        categoryType: "provider",
        name: provider,
      };
      if (content.additionaiInfo === null) {
        content.additionaiInfo = [];
      }
      let cats = [obj1, obj2, obj3];
      cats.forEach((x) => {
        content.additionaiInfo.push({ title: x.categoryType, content: x.name });
      });
    }
    if (content.additionaiInfo !== null) {
      content.additionaiInfo
        .filter((x) => x.title === "provider")
        .map((x) => (x.title = "Производитель"));
      content.additionaiInfo
        .filter((x) => x.title === "seedType")
        .map((x) => (x.title = "Вид"));
      content.additionaiInfo
        .filter((x) => x.title === "growType")
        .map((x) => (x.title = "Способ выращивания"));
    }
    setProduct(content);
  };
  useEffect(() => {
    getPics();
    fetchProduct()
  }, [props.id]);
 

let link="/add/:"+props.id
  return (
    <div className="card">
      <img src={img} className="card-img"></img>
      <div className="card-name"><b>{props.name}</b>{props.isAdmin &&<div className="edit-buttons"><a><NavLink to={link} className="add-new"><Edit className="edit-button" /></NavLink></a><a onClick={function(){props.handleDelete(props.id)}}><Delete className="edit-button" /></a></div>}</div>
      <div className="card-provider">{props.provider}</div>
      <hr className="card-hr"/>
      <div className="card-desc">{
      productInfo.additionaiInfo !== null &&
              productInfo.additionaiInfo.map((x) => (
                <div>
                  <div className="chars-table ">
                    <div> {x.title}</div>
                    <div className="green"> {x.content}</div>
                  </div>
                  <hr className="chars-table-hr" />
                </div>
              ))}</div>
      <hr className="card-hr" style={{ margin: "0px 0px 20px 0px"}} />
      <div className="price-section">
      
      <button  className="button card-button" onClick={function () {props.openModal(props.id, img)}}>Подробнее</button>

      <div className="card-name"><b>{props.price}р.</b><p className="price">/упаковка</p></div>
      </div>
    </div>
  );
};

export default Card;