let state ={
    list:[{
        id:1,
        name:'Розы розовые',
        desc:`Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу биф-томатов. От всходов до первого сбора урожая 105-110  дней.
        Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        type:"Розы",
        growType:"В саду",
        img:'https://crosti.ru/patterns/00/25/a0/a8_picture_91c0d00c.jpg',
        characteristics: [{"Торговая марка":"Белорусские семена"},
                          {"Страна Производства":"Россия"},
                          {"Гибрид":"Сортовой"},
                          {"Высота растения":"до 30 см"},
                          {"Жизненный цикл":"однолетнее"},
                          {"Цвет":"Розовый"},
                          {"qww1":"11"},
                          {"11 11":"111"}, 
    ]

    },
    {
        id:2,
        name:'Розы алые',
        desc:`Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу биф-томатов. От всходов до первого сбора урожая 105-110  дней.
        Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        img:'https://binomen.ru/photo/uploads/posts/2024-01/1706591031_binomen-ru-p-roza-kustarnik-vkontakte-13.jpg',
    },
    {
        id:3,
        name:'Анютины глазки',
        desc:`Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу биф-томатов. От всходов до первого сбора урожая 105-110  дней.
        Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        img:'https://crosti.ru/patterns/00/25/a0/a8_picture_91c0d00c.jpg',
    },
    {
        id:4,
        name:'Томаты черри',
        desc:`Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу биф-томатов. От всходов до первого сбора урожая 105-110  дней.
        Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        img:'https://static.tildacdn.com/tild6261-3737-4633-b738-363236383833/1507103906_Tomate_Pi.jpg',
    },
    {
        id:5,
        name:'Хризантема',
        desc:`Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу биф-томатов. От всходов до первого сбора урожая 105-110  дней.
        Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        img:'https://i.pinimg.com/originals/d6/ca/13/d6ca13659c0c4dd70396a42b7cf01b99.jpg',
    },
    {
        id:6,
        name:'Лук',
        desc:`Сорт среднеранний  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        img:'https://sad-i-dom.com/uploads/posts/2022-11/1667558678_1.webp',
    },
    {
        id:7,
        name:'Анютины глазки',
        desc:`Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу биф-томатов. От всходов до первого сбора урожая 105-110  дней.
        Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        img:'https://crosti.ru/patterns/00/25/a0/a8_picture_91c0d00c.jpg',
    },
    {
        id:8,
        name:'Анютины глазки',
        desc:`Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу биф-томатов. От всходов до первого сбора урожая 105-110  дней.
        Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        img:'https://crosti.ru/patterns/00/25/a0/a8_picture_91c0d00c.jpg',
    },
    {
        id:9,
        name:'Анютины глазки',
        desc:`Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу биф-томатов. От всходов до первого сбора урожая 105-110  дней.
        Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        img:'https://crosti.ru/patterns/00/25/a0/a8_picture_91c0d00c.jpg',
    },
    {
        id:10,
        name:'Анютины глазки',
        desc:`Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу биф-томатов. От всходов до первого сбора урожая 105-110  дней.
        Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        img:'https://crosti.ru/patterns/00/25/a0/a8_picture_91c0d00c.jpg',
    },
    {
        id:11,
        name:'Анютины глазки',
        desc:`Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу биф-томатов. От всходов до первого сбора урожая 105-110  дней.
        Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        img:'https://crosti.ru/patterns/00/25/a0/a8_picture_91c0d00c.jpg',
    },
    {
        id:12,
        name:'Анютины глазки',
        desc:`Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу биф-томатов. От всходов до первого сбора урожая 105-110  дней.
        Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        img:'https://crosti.ru/patterns/00/25/a0/a8_picture_91c0d00c.jpg',
    },
    {
        id:13,
        name:'Анютины глазки',
        desc:`Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу биф-томатов. От всходов до первого сбора урожая 105-110  дней.
        Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        img:'https://crosti.ru/patterns/00/25/a0/a8_picture_91c0d00c.jpg',
    },
    {
        id:14,
        name:'Анютины глазки',
        desc:`Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу биф-томатов. От всходов до первого сбора урожая 105-110  дней.
        Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        type:"Розы",
        growType:"В саду",
        img:'https://crosti.ru/patterns/00/25/a0/a8_picture_91c0d00c.jpg',
        characteristics: [{"Торговая марка":"Белорусские семена"},
                          {"Страна Производства":"Россия"},
                          {"Гибрид":"Сортовой"},
                          {"Высота растения":"до 30 см"},
                          {"Жизненный цикл":"однолетнее"},
                          {"Цвет":"Розовый"},
                          {"11":"11"},
                          {"11 11":"111"}, 
    ]
    },
    {
        id:15,
        name:'Анютины глазки',
        desc:`Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу биф-томатов. От всходов до первого сбора урожая 105-110  дней.
        Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        img:'https://crosti.ru/patterns/00/25/a0/a8_picture_91c0d00c.jpg',
    },
    {
        id:16,
        name:'Анютины глазки',
        desc:`Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу биф-томатов. От всходов до первого сбора урожая 105-110  дней.
        Первое соцветие образуется над 9 листом, последующие через 3  листа. Сорт среднеранний, индетер минантный,  относится к типу би ф-томатов. От всходов до первого сбора урожая 105-110  дней. `,
        price:4.5,
        provider:'Белорусские семена!!!',
        img:'https://crosti.ru/patterns/00/25/a0/a8_picture_91c0d00c.jpg',
    },


]
}

export default state;